import moment from "moment";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import Navbar from "../Navbar";

function Thanks() {
  let navigate = useNavigate();
  const location = useLocation();

  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
    room: 1,
    age: 1,
  });
  const handlebook = () => {
    navigate("/hotel", { state: { dates, options } });
  };
  return (
    <>
      <Navbar />

      <div className="container-fluid mt-5 pt-4 ">
        <div className="row">
          <div className="col-lg-12 mt-3 ">
            <div className="con-1">
              <div>
                <h5>Reservation Confirmation For</h5>
                <h4 className="fw-bold">{location.state.firstname}</h4>
              </div>
              <div>
                <h5>Reservation Id</h5>
                <h4 className="fw-bold text-center">
                  {location.state.bookingno}
                </h4>
              </div>
            </div>
          </div>
          <hr />
          <div className="col-lg-8 my-2 ">
            <div className="than">
              <h3>
                Your Reservation at <span>lepalais Hotel</span> is Confirmed
              </h3>
              <h5>We look forward to seeing you at our hotel soon</h5>
              <hr />
              <h5 className="thank-h5">
                You've paid <span>INR 0.00</span> now.pay the remaing balance of
                <span> INR {location.state.price}.00 </span>
                later
              </h5>

              <div className="con-2">
                <button onClick={handlebook} className="book-btn2">
                  Make Another Reservation
                </button>
                OR
                <Link to="/" className="name">
                  Go back Website
                </Link>
              </div>
              <hr />
              <h2 className="text-center tha">
                Thank You For Your Reservation{" "}
              </h2>
            </div>
          </div>

          <div className="col-lg-4 my-2">
            <div class="card form-book ">
              <div class="card-header ">
                <h5 class="my-2 text-center">Booking Summary</h5>
              </div>
              <div class="card-body">
                <div className="form-date">
                  <div>
                    <span> Check In</span>
                    <h5 className="mt-3 fw-bold">
                      {moment(location.state.checkin).format("DD-MM-YYYY")}
                    </h5>
                  </div>
                  <div>
                    <span> Check Out</span>
                    <h5 className="mt-3  fw-bold">
                      {moment(location.state.checkout).format("DD-MM-YYYY")}
                    </h5>
                  </div>
                </div>
                <ul class="list-group list-group-flush ">
                  <li class="list-group-item my-4 ">
                    Room Type
                    <span className=" fw-bold">{location.state.type}</span>
                  </li>
                  <li class="list-group-item my-3">
                    No of Nights
                    <span className="me-5 fw-bold">{location.state.night}</span>
                  </li>
                  <li class="list-group-item my-3">
                    No of Rooms
                    <span className="me-5 fw-bold">{location.state.room}</span>
                  </li>
                  <li class="list-group-item mt-3">
                    Guest
                    <span className="fw-bold me-4">
                      {location.state.noofguest} Person
                    </span>
                  </li>
                </ul>
                <hr />
                <ul class="list-group list-group-flush">
                  <li class="list-group-item  mt-3">
                    <div>
                      <strong className="bill-total">Total</strong>
                    </div>
                    <span>
                      <strong className="text-success fs-3">
                        <span className="me-2 fs-3">₹</span>
                        {location.state.price}
                      </strong>
                      {/* <sub className="text-muted "> / Day</sub> */}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Thanks;
