import React from "react";

function Gallery() {
  return (
    <section id="gallery">
      <h2 className="gallery-h1 ">Gallery</h2>
      <div className="container-fluid gallery">
        <div className="gallery-img">
          <img
            src="images/ouhgqjPfzQWrL22ibPjXhVvhgxuIj6k9FVoyTbZklk4=_plaintext_638009874494591364.jpg"
            alt=""
            className="gal-img"
          />
        </div>
        <div className="gallery-img">
          <img
            src="images/IMG-20221010-WA0016.jpg"
            alt=""
            className="gal-img"
          />
        </div>
        <div className="gallery-img">
          <img
            src="images/IMG-20221010-WA0018.jpg"
            alt=""
            className="gal-img"
          />
        </div>
        <div className="gallery-img">
          <img
            src="images/IMG-20221010-WA0021.jpg"
            alt=""
            className="gal-img"
          />
        </div>
        <div className="gallery-img">
          <img
            src="images/IMG-20221010-WA0020.jpg"
            alt=""
            className="gal-img"
          />
        </div>
        <div className="gallery-img">
          <img
            src="images/IMG-20221010-WA0023.jpg"
            alt=""
            className="gal-img"
          />
        </div>
        <div className="gallery-img">
          <img
            src="images/IMG-20221010-WA0026.jpg"
            alt=""
            className="gal-img"
          />
        </div>
        <div className="gallery-img">
          <img
            src="images/IMG-20221010-WA0027.jpg"
            alt=""
            className="gal-img"
          />
        </div>
        <div className="gallery-img">
          <img
            src="images/IMG-20221010-WA0029.jpg"
            alt=""
            className="gal-img"
          />
        </div>
      </div>
    </section>
  );
}

export default Gallery;
