import React, { useState } from "react";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { BsClock, BsSnow3, BsTv, BsWifi } from "react-icons/bs";
import { FaParking, FaRestroom } from "react-icons/fa";
import { GiCctvCamera } from "react-icons/gi";
import { MdOutlineSmokeFree } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { useEffect } from "react";
import axios from "axios";
import { v4 as uuid } from "uuid";
function HotelList() {
  const navigate = useNavigate();
  const location = useLocation();
  const unique_id = uuid();
  const id = unique_id.slice(0, 6);
  const [openDate, setOpenDate] = useState(false);
  const [select, setselect] = useState(false);
  const [dates, setDates] = useState([
    {
      startDate: location.state.dates[0].startDate,
      endDate: location.state.dates[0].endDate,
      key: "selection",
    },
  ]);
  const [openOptions, setOpenOptions] = useState(false);
  // const [bookingId, setBookingId] = useState(null);
  // const generateId = () => {
  //   const randomNumber = Math.floor(Math.random() * 1000);
  //   const id = `R${randomNumber}`;
  //   setBookingId(id);
  // };
  // console.log(bookingId);
  const [options, setOptions] = useState({
    adult: location.state.options.adult,
    children: location.state.options.children,
    room: location.state.options.room,
  });

  const [bill, setBill] = useState({
    name: "",
    amount: "0",
  });
  const [no, setno] = useState({
    bno: "",
  });
  const [adata, setavailable] = useState([]);

  let rooms = [
    {
      type: "Single Room",
      price: "1000",
      img: "images/single.jpg",
    },
    {
      img: "images/double.jpg",
      price: "2000",
      type: "Double Room",
    },
    {
      img: "images/deluxe.jpg",
      price: "3000",
      type: " Deluxe Room",
    },
    {
      img: "images/super.jpg",
      price: "4000",
      type: "Super Deluxe Room",
    },
  ];

  const handleOption = (name, operation) => {
    setOptions((prev) => {
      return {
        ...prev,
        [name]: operation === "i" ? options[name] + 1 : options[name] - 1,
      };
    });
  };
  let handleopen = () => {
    setOpenOptions(!openOptions);
    setOpenDate(false);
  };
  let handledate = () => {
    setOpenOptions(false);
    setOpenDate(!openDate);
  };

  const handleSearch = () => {
    setOpenOptions(false);
    setOpenDate(false);
    navigate("/hotel", { state: { dates, options } });
  };
  const handleBook = () => {
    select
      ? navigate("/book", { state: { dates, options, bill, no, nights } })
      : Swal.fire({
          icon: "info",
          title: "Select A Room !",
          timer: "2000",
        });
  };

  const addroom = (e) => {
    setselect(true);

    setBill({
      name: e.type,
      amount: e.price,
    });
  };
  const data = async () => {
    try {
      let bookingdata = await axios.get(
        "https://api.lepalais.hotel.digiacuity.com/reservelist"
      );
      let available = await axios.get(
        "https://api.lepalais.hotel.digiacuity.com/roomsavailable"
      );
      setavailable(available.data);
      setno({
        bno: id,
      });
    } catch (error) {
      alert("Something went wrong booking" + error);
    }
  };
  useEffect(() => {
    data();
    // generateId();
  }, []);
  let time =
    location.state.dates[0].endDate - location.state.dates[0].startDate;
  let nights = time / (1000 * 3600 * 24);

  // var today = new Date().toISOString().split("T")[0];
  return (
    <>
      <div className="container-fluid mt-5">
        <div className="row ">
          <div className="col-lg-9 ps-4 mb-3">
            <div class="row">
              <div className="check-form2">
                <div className="headerSearchItem">
                  {/* <div>
              <label htmlFor="from">Date</label>
            </div> */}

                  <span
                    onClick={handledate}
                    className="headerSearchText"
                    placeholder="CheckIn To CHeckOut"
                  >{`${format(dates[0].startDate, "dd/MM/yyyy")} to ${format(
                    dates[0].endDate,
                    "dd/MM/yyyy"
                  )}`}</span>
                  {openDate && (
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => setDates([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={dates}
                      className="date2"
                      minDate={new Date()}
                    />
                  )}
                </div>
                <div className="headerSearchItem">
                  <span
                    onClick={handleopen}
                    className="headerSearchText"
                  >{`${options.adult} Adult |   ${options.children} Children |  ${options.room} Room`}</span>
                  {openOptions && (
                    <div className="options2">
                      <div className="optionItem">
                        <span className="optionText">Adult</span>
                        <div className="optionCounter">
                          <button
                            disabled={options.adult <= 1}
                            className="optionCounterButton"
                            onClick={() => handleOption("adult", "d")}
                          >
                            -
                          </button>
                          <span className="optionCounterNumber">
                            {options.adult}
                          </span>
                          <button
                            className="optionCounterButton"
                            disabled={options.adult >= 10}
                            onClick={() => handleOption("adult", "i")}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="optionItem">
                        <span className="optionText">Children</span>
                        <div className="optionCounter">
                          <button
                            disabled={options.children <= 0}
                            className="optionCounterButton"
                            onClick={() => handleOption("children", "d")}
                          >
                            -
                          </button>
                          <span className="optionCounterNumber">
                            {options.children}
                          </span>
                          <button
                            className="optionCounterButton"
                            disabled={options.children >= 10}
                            onClick={() => handleOption("children", "i")}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="optionItem">
                        <span className="optionText">Room</span>
                        <div className="optionCounter">
                          <button
                            disabled={options.room <= 1}
                            className="optionCounterButton"
                            onClick={() => handleOption("room", "d")}
                          >
                            -
                          </button>
                          <span className="optionCounterNumber">
                            {options.room}
                          </span>
                          <button
                            className="optionCounterButton"
                            disabled={options.room >= 10}
                            onClick={() => handleOption("room", "i")}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="headerSearchItem">
                  <button className="check-btn" onClick={handleSearch}>
                    Check
                  </button>
                </div>
              </div>

              {rooms.map((e) => {
                return (
                  <>
                    <div class="row   mt-3 mx-0 px-0">
                      <div className="col-lg-12 form-room ">
                        <div class="col-lg-3  ">
                          <img src={e.img} class="form-img" />
                        </div>
                        <div class="col-lg-6 ">
                          <h5 class="form-tit mt-3">{e.type}</h5>

                          <ul className="form-ul">
                            <li>
                              <BsWifi className="icon2" />
                              Wi-fi
                            </li>
                            <li>
                              <BsSnow3 className="icon2" />
                              Ac
                            </li>
                            <li>
                              <BsTv className="icon2" />
                              tv
                            </li>

                            <li>
                              <FaRestroom className="icon2" />
                              bathroom
                            </li>
                            <li>
                              <FaParking className="icon2" />
                              parking
                            </li>
                            <li>
                              <GiCctvCamera className="icon2" />
                              cctv
                            </li>

                            <li>
                              <BsClock className="icon2" />
                              24/7 Service
                            </li>
                            <li>
                              <MdOutlineSmokeFree className="icon2" />
                              non smoking
                            </li>
                          </ul>
                        </div>
                        <div class="col-lg-3 form-pri mx-auto mb-3">
                          <h3>₹ {e.price}</h3>
                          <button
                            className="check-btn mt-3"
                            onClick={() => addroom(e)}
                            value="single"
                          >
                            ADD
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div className="col-lg-3 my-3">
            <div class="card form-book ">
              <div class="card-header py-2">
                <h5 class="mb-0">Booking Summary</h5>
              </div>
              <div class="card-body">
                <div className="form-date">
                  <div>
                    <span> CheckIn</span>
                    <h5 className="mt-3">
                      {moment(location.state.dates[0].startDate).format(
                        "DD-MM-YYYY"
                      )}
                    </h5>
                  </div>
                  <div>
                    <span> CheckOut</span>
                    <h5 className="mt-3">
                      {moment(location.state.dates[0].endDate).format(
                        "DD-MM-YYYY"
                      )}
                    </h5>
                  </div>
                </div>
                <ul class="list-group list-group-flush ">
                  <li class="list-group-item  " required>
                    Room Type
                    <span className=" fw-bold">{bill.name}</span>
                  </li>
                  <li class="list-group-item ">
                    No of Nights
                    <span className="me-5 fw-bold">{nights}</span>
                  </li>
                  <li class="list-group-item ">
                    No of Rooms
                    <span className="me-5 fw-bold">
                      {location.state.options.room}
                    </span>
                  </li>
                  <li class="list-group-item">
                    Guest
                    <span className="fw-bold">
                      {location.state.options.adult} Adult
                      <span className="fw-bold ms-3">
                        {location.state.options.children} Children
                      </span>
                    </span>
                  </li>
                </ul>
                <hr />
                <ul class="list-group list-group-flush">
                  <li class="list-group-item ">
                    Sub Total
                    <span className="fw-bold">
                      ₹ {nights * location.state.options.room * bill.amount}
                    </span>
                  </li>
                  <li class="list-group-item ">
                    Tax Fees
                    <span className="fw-bold">
                      ₹{" "}
                      {nights *
                        location.state.options.room *
                        bill.amount *
                        0.18}
                    </span>
                  </li>
                  <li class="list-group-item  mt-2">
                    <div>
                      <strong className="bill-total">Total</strong>
                    </div>
                    <span>
                      <strong className="text-success fs-3">
                        <span className="me-2">₹</span>
                        {nights * location.state.options.room * bill.amount +
                          nights *
                            location.state.options.room *
                            bill.amount *
                            0.18}
                      </strong>
                      {/* <sub className="text-muted "> / Day</sub> */}
                    </span>
                  </li>
                </ul>
                <div className="d-flex justify-content-center">
                  <button className="check-btn mt-3" onClick={handleBook}>
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HotelList;
