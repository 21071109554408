import React from "react";
import Footer from "../../components/Footer";
import HotelList from "../../components/Hotel/HotelList";
import Navbar2 from "../../components/Hotel/Navbar2";
import Navbarc from "../../components/Navbar";

function Hotel() {
  return (
    <>
      <Navbar2 />
      <div className="hotel-li">
        <HotelList />
      </div>
      <Footer />
    </>
  );
}

export default Hotel;
