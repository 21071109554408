import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";

function Navbarc() {
  let navigate = useNavigate();
  const handleform2 = () => {
    navigate("/hotel", {
      state: {
        from: `MM-DD-YYYY`,
        to: `MM-DD-YYYY`,
        adult: "0",
        child: "0",
        room: "0",
      },
    });
  };
  return (
    <>
      <Navbar collapseOnSelect expand="lg" fixed="top">
        <Container>
          <Navbar.Brand>
            <Link to="/">
              {" "}
              <img src="images/Le Palais Logo.png" className="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <Nav.Link href="#home">HOME</Nav.Link>
              <Nav.Link eventKey={2} href="#about">
                ABOUT
              </Nav.Link>
              <Nav.Link eventKey={3} href="#rooms">
                ROOMS
              </Nav.Link>
              <Nav.Link eventKey={4} href="#gallery">
                GALLERY
              </Nav.Link>
              <Nav.Link eventKey={5} href="#contact">
                CONTACT
              </Nav.Link>
              {/* <Nav.Link eventKey={6}>
                <Button className="check-btn" onClick={handleform2}>
                  Book
                </Button>
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navbarc;
