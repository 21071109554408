import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addDays, format } from "date-fns";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

function Form2() {
  const [openDate, setOpenDate] = useState(false);

  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ]);
  const [openOptions, setOpenOptions] = useState(false);
  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
    room: 1,
  });
  const handleOption = (name, operation) => {
    setOptions((prev) => {
      return {
        ...prev,
        [name]: operation === "i" ? options[name] + 1 : options[name] - 1,
      };
    });
  };
  let handleopen = () => {
    setOpenOptions(!openOptions);
    setOpenDate(false);
  };
  let handledate = () => {
    setOpenOptions(false);
    setOpenDate(!openDate);
  };
  let navigate = useNavigate();
  // const handleform = (e) => {
  //   e.preventDefault();
  //   navigate("/hotel", { state: { from, to, adult, child, room } });
  // };
  // const date = new Date();

  // let min =
  //   date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

  // var today = new Date().toISOString().split("T")[0];
  // const [startDate, setStartDate] = useState(new Date());
  const handleSearch = () => {
    setOpenOptions(false);
    setOpenDate(false);
    navigate("/hotel", { state: { dates, options } });
  };
  return (
    <>
      <div className="check-form">
        <div className="headerSearchItem">
          {/* <div>
              <label htmlFor="from">Date</label>
            </div> */}

          <span
            onClick={handledate}
            className="headerSearchText"
            placeholder="CheckIn To CHeckOut"
          >{`${format(dates[0].startDate, "dd/MM/yyyy")} to ${format(
            dates[0].endDate,
            "dd/MM/yyyy"
          )}`}</span>
          {openDate && (
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setDates([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dates}
              className="date"
              minDate={new Date()}
            />
          )}
        </div>
        {/* <div className="date-select">
            <div>
              <label htmlFor="to">To</label>
            </div>
            <input
              type="date"
              id="to"
              name="to"
              min={today}
              className="date-input"
              onChange={(e) => setto(e.target.value)}
              required
            />
          </div> */}
        <div className="headerSearchItem">
          <span
            onClick={handleopen}
            className="headerSearchText"
          >{`${options.adult} Adult | ${options.children} Children | ${options.room} Room`}</span>
          {openOptions && (
            <div className="options">
              <div className="optionItem">
                <span className="optionText">Adult</span>
                <div className="optionCounter">
                  <button
                    disabled={options.adult <= 1}
                    className="optionCounterButton"
                    onClick={() => handleOption("adult", "d")}
                  >
                    -
                  </button>
                  <span className="optionCounterNumber">{options.adult}</span>
                  <button
                    className="optionCounterButton"
                    disabled={options.adult >= 10}
                    onClick={() => handleOption("adult", "i")}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="optionItem">
                <span className="optionText">Children</span>
                <div className="optionCounter">
                  <button
                    disabled={options.children <= 0}
                    className="optionCounterButton"
                    onClick={() => handleOption("children", "d")}
                  >
                    -
                  </button>
                  <span className="optionCounterNumber">
                    {options.children}
                  </span>
                  <button
                    className="optionCounterButton"
                    disabled={options.children >= 10}
                    onClick={() => handleOption("children", "i")}
                  >
                    +
                  </button>
                </div>
              </div>
              <div>
                {/* {age ? (
                  <>
                    <Form.Select aria-label="Default select example">
                      <option>Select Age</option>
                      <option value="1">1 year</option>
                      <option value="2">2 year</option>
                      <option value="3">3 year</option>
                      <option value="4">4 year</option>
                      <option value="5">5 year</option>
                      <option value="6">6 year</option>
                      <option value="7">7 year</option>
                      <option value="8">8 year</option>
                      <option value="9">9 year</option>
                      <option value="10">10 year</option>
                      <option value="11">11 year</option>
                      <option value="12">12 year</option>
                      <option value="13">13 year</option>
                      <option value="14">14 year</option>
                      <option value="15">15 year</option>
                      <option value="16">16 year</option>
                      <option value="17">17 year</option>
                    </Form.Select>
                  </>
                ) : null}
                {age2 ? (
                  <>
                    <Form.Select aria-label="Default select example">
                      <option>Select Age</option>
                      <option value="1">1 year</option>
                      <option value="2">2 year</option>
                      <option value="3">3 year</option>
                      <option value="4">4 year</option>
                      <option value="5">5 year</option>
                      <option value="6">6 year</option>
                      <option value="7">7 year</option>
                      <option value="8">8 year</option>
                      <option value="9">9 year</option>
                      <option value="10">10 year</option>
                      <option value="11">11 year</option>
                      <option value="12">12 year</option>
                      <option value="13">13 year</option>
                      <option value="14">14 year</option>
                      <option value="15">15 year</option>
                      <option value="16">16 year</option>
                      <option value="17">17 year</option>
                    </Form.Select>
                  </>
                ) : null} */}
              </div>
              <div className="optionItem">
                <span className="optionText">Room</span>
                <div className="optionCounter">
                  <button
                    disabled={options.room <= 1}
                    className="optionCounterButton"
                    onClick={() => handleOption("room", "d")}
                  >
                    -
                  </button>
                  <span className="optionCounterNumber">{options.room}</span>
                  <button
                    className="optionCounterButton"
                    disabled={options.room >= 10}
                    onClick={() => handleOption("room", "i")}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="headerSearchItem">
          <button className="check-btn" onClick={handleSearch}>
            Check
          </button>
        </div>
      </div>
      {/* <div className="room-quantity mx-auto d-flex w-md-100 me-4  "> */}
      {/* <div className="single-quantity ms-2">
            <div>
              <label>Adult</label>
            </div>
            <select
              name="adults"
              id="adults"
              onChange={(e) => setadult(e.target.value)}
              required
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </div>

          <div className="single-quantity">
            <div>
              <label>Child</label>
            </div>

            <select
              name="children"
              id="children"
              onChange={(e) => setchild(e.target.value)}
              required
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </div>
          <div className="single-quantity">
            <div>
              <label>Rooms</label>
            </div>
            <select
              name="rooms"
              id="room"
              onChange={(e) => setroom(e.target.value)}
              required
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </div> */}
      {/* <span
          onClick={() => setOpenOptions(!openOptions)}
          className="headerSearchText"
        >{`${options.adult} Adult | ${options.children} Children | ${options.room} Room`}</span>
        {openOptions && (
          <div className="options">
            <div className="optionItem">
              <span className="optionText">Adult</span>
              <div className="optionCounter">
                <button
                  disabled={options.adult <= 1}
                  className="optionCounterButton"
                  onClick={() => handleOption("adult", "d")}
                >
                  -
                </button>
                <span className="optionCounterNumber">{options.adult}</span>
                <button
                  className="optionCounterButton"
                  onClick={() => handleOption("adult", "i")}
                >
                  +
                </button>
              </div>
            </div>
            <div className="optionItem">
              <span className="optionText">Children</span>
              <div className="optionCounter">
                <button
                  disabled={options.children <= 0}
                  className="optionCounterButton"
                  onClick={() => handleOption("children", "d")}
                >
                  -
                </button>
                <span className="optionCounterNumber">{options.children}</span>
                <button
                  className="optionCounterButton"
                  onClick={() => handleOption("children", "i")}
                >
                  +
                </button>
              </div>
            </div>
            <div className="optionItem">
              <span className="optionText">Room</span>
              <div className="optionCounter">
                <button
                  disabled={options.room <= 1}
                  className="optionCounterButton"
                  onClick={() => handleOption("room", "d")}
                >
                  -
                </button>
                <span className="optionCounterNumber">{options.room}</span>
                <button
                  className="optionCounterButton"
                  onClick={() => handleOption("room", "i")}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        <input
          type="submit"
          value="CHECK AVAILABILITY"
          className="check-btn mt-3"
          onClick={handleSearch}
        />
      </div>
    */}
    </>
  );
}

export default Form2;
